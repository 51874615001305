import { createGlobalStyle } from "styled-components";

import font from "./Milk.otf";
import font2 from "./BigJohnPRO-Bold.otf";
import font3 from "./BigJohnPRO-Regular.otf";
import font4 from "./BigJohnPRO-Light.otf";

export default createGlobalStyle`
    @font-face {
        font-family: 'Milk';
        src: local('Milk'), local('Milk'),
        url(${font}) format('otf'),
        
    }
    @font-face {
        font-family: 'BigJohnPro-Bold';
        src: local('BigJohnPro-Bold'), local('BigJohnPro-Bold),
        url(${font2}) format('otf'),
      

        
        
    }
    @font-face {
        font-family: 'BigJohnPro-Regular';
        src: local('BigJohnPro-Regular'), local('BigJohnPro-Regular'),
        url(${font3}) format('otf');

        
        
    }
     @font-face {
        font-family: 'BigJohnPro-Light';
        src: local('BigJohnPro-Light'), local('BigJohnPro-Light'),
        url(${font4}) format('otf');

        
        
    }
`;
