import React, { useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import "./styles.css";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import Sequence from "./Sequence";
import GlobalFonts from "./fonts/fonts";
import Intro from "./sections/intro/intro";
import explorance from "./images/explorance.png";
import cirque from "./images/cirque.png";
import kontrol from "./images/kontrol.png";
import accenture from "./images/accenture.png";
import zara from "./images/zara.png";
import githuborange from "./images/githuborange.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import buzzle from "./images/buzzle.png";
import Typography from "@mui/material/Typography";
import thankloop from "./images/thankloop.png";
import web from "./images/web.png";
import githubblue from "./images/githubblue.png";
import linkedin from "./images/linkedin.png";
import email from "./images/email.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F4D5C6",
  border: "3px solid #8D3300",
  boxShadow: 24,
  borderRadius: "8%",
};
const App = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const [loading, setLoading] = useState(true);
  const ref = useRef();
  let current;
  // useEffect(() => {
  //   setLoading(false);
  // });
  const handleLoading = () => {
    // setLoading(true);
    // let index3 = 0;
    // for (var i = 0; i < 507; i++) {
    //   index3 = index3 + 1;
    //   let index4 = padLeadingZeros(index3, 4);
    //   var img2 =
    //     "https://res.cloudinary.com/thankloop/image/upload/q_auto,f_auto,fl_lossy,w_auto,dpr_auto,c_limit/v1638663959/portfolio/" +
    //     index4 +
    //     ".png";
    // }

    setLoading(false);
  };

  useEffect(() => {
    window.addEventListener("load", handleLoading);
    return () => window.removeEventListener("load", handleLoading);
  }, []);
  return (
    <div className="App">
      {console.log(loading)}
      <GlobalFonts />

      <Controller>
        <Scene duration="1000%" triggerHook="onLeave" pin>
          {(progress) => (
            <div
              style={{ height: "100vh", position: "relative" }}
              className="cursor"
            >
              {loading ? (
                <div className="header">
                  <div className="seven"></div>
                </div>
              ) : (
                <div>
                  {console.log("progress" + progress)}
                  <div className="rotate">
                    <div className="phone"></div>
                    <div className="message">Please rotate your device!</div>
                  </div>
                  <div className="titlesportrait">
                    {progress >= 0 && progress < 0.151 ? (
                      <div className="title1">
                        <h1 className="header"> HI! I'M MASSY, </h1>
                        <p className="header2">
                          {" "}
                          I'm a software engineer in training <br></br> and
                          video maker{" "}
                        </p>
                        <div className="scroll-downs">
                          <div className="mousey">
                            <div className="scroller"></div>
                          </div>
                        </div>
                        <p className="scrolling">Scroll slowly</p>
                      </div>
                    ) : null}
                    {progress >= 0 && progress < 0.151 ? (
                      <div className="title1">
                        <div className="scroll-downs">
                          <div className="mousey">
                            <div className="scroller"></div>
                          </div>
                        </div>
                        <p className="scrolling">Scroll slowly</p>
                      </div>
                    ) : null}
                    {progress > 0.212 && progress < 0.301 ? (
                      <Tween
                        from={{
                          css: {
                            opacity: "0",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                        to={{
                          css: {
                            opacity: "1",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                      >
                        <div className="title3">
                          <p className="header3">from Montreal , Canada</p>
                        </div>
                      </Tween>
                    ) : null}

                    {progress > 0.424 && progress < 0.557 ? (
                      <Tween
                        from={{
                          css: {
                            opacity: "0",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                        to={{
                          css: {
                            opacity: "1",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                      >
                        <div>
                          <p className="header4">
                            I've worked in various different industries
                          </p>
                        </div>
                      </Tween>
                    ) : null}
                    {progress > 0.46 && progress < 0.557 ? (
                      <Tween
                        from={{
                          css: {
                            opacity: "0",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                        to={{
                          css: {
                            opacity: "1",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                      >
                        <div className="title4">
                          <p className="education">Education</p>
                          <br></br>
                          <img className="img1" src={explorance}></img>
                          <p className="media">
                            Media intern <br></br>2018
                          </p>
                        </div>
                      </Tween>
                    ) : null}
                    {progress > 0.48 && progress < 0.557 ? (
                      <Tween
                        from={{
                          css: {
                            opacity: "0",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                        to={{
                          css: {
                            opacity: "1",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                      >
                        <div className="title5">
                          <p className="education">Entertainment</p>
                          <br></br>
                          <img className="img1" src={cirque}></img>
                          <p className="swe">
                            Software Engineering <br></br>Intern <br></br>
                            2019-2020
                          </p>
                        </div>
                      </Tween>
                    ) : null}
                    {progress > 0.5 && progress < 0.557 ? (
                      <Tween
                        from={{
                          css: {
                            opacity: "0",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                        to={{
                          css: {
                            opacity: "1",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                      >
                        <div className="title6">
                          <p className="education">Eco-tech</p>
                          <br></br>
                          <img className="img2" src={kontrol}></img>
                          <p className="swe">
                            Full-Stack Developer <br></br>Intern <br></br> 2020
                          </p>
                        </div>
                      </Tween>
                    ) : null}
                    {progress > 0.52 && progress < 0.557 ? (
                      <Tween
                        from={{
                          css: {
                            opacity: "0",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                        to={{
                          css: {
                            opacity: "1",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                      >
                        <div className="title7">
                          <p className="education">Consulting</p>
                          <br></br>
                          <img className="img3" src={accenture}></img>
                          <p className="media">
                            Technology analyst <br></br>2021
                          </p>
                        </div>
                      </Tween>
                    ) : null}
                    {progress > 0.625 && progress < 0.761 ? (
                      <Tween
                        from={{
                          css: {
                            opacity: "0",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                        to={{
                          css: {
                            opacity: "1",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                      >
                        <div>
                          <p className="header5">
                            And love pursuing projects of my own (click the
                            bright orange pieces!)
                          </p>
                        </div>
                      </Tween>
                    ) : null}
                    {progress > 0.625 && progress < 0.761 ? (
                      <Tween
                        from={{
                          css: {
                            opacity: "0",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                        to={{
                          css: {
                            opacity: "1",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                      >
                        <div className="moda">
                          <button className="buttonmoda" onClick={handleOpen} />

                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style} className="modal">
                              <Typography
                                id="modal-modal-title"
                                variant="h3"
                                component="h2"
                                style={{
                                  fontFamily: "Montserrat",
                                  fontWeight: "800",
                                  color: "#8D3300",
                                  textAlign: "center",
                                }}
                              >
                                Moda
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                  margin: "2vh",
                                }}
                              >
                                Google Chrome Extension that helps users find
                                sustainable arternatives to fast fashion
                                garments
                              </Typography>
                              <img
                                style={{
                                  marginLeft: "2vh",
                                }}
                                src={zara}
                                className="image-modal-zara"
                              />
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "Raleway",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Tech stack :{" "}
                                </p>{" "}
                                Tensorflow , Keras , Python , JavaScript
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                }}
                              >
                                <a
                                  href="https://github.com/freyaryd/moda"
                                  style={{ color: "#8D3300" }}
                                >
                                  <img
                                    style={{ marginRight: "1vh" }}
                                    src={githuborange}
                                  />
                                  View on GitHub
                                </a>
                              </Typography>
                            </Box>
                          </Modal>
                        </div>
                        <div className="acad">
                          <button
                            className="buttonacad"
                            onClick={handleOpen1}
                          />
                          <Modal
                            open={open1}
                            onClose={handleClose1}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style} className="modal">
                              <Typography
                                id="modal-modal-title"
                                variant="h3"
                                component="h2"
                                style={{
                                  fontFamily: "Montserrat",
                                  fontWeight: "800",
                                  color: "#8D3300",
                                  textAlign: "center",
                                }}
                              >
                                Academics
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                  margin: "2vh",
                                }}
                              >
                                I completed my software engineering degree at
                                McGill University in May 2021 , Here are some
                                projects from my favorite courses and
                                extracurriculars :)
                              </Typography>

                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  fontWeight: "bold",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                }}
                              >
                                <img
                                  style={{ marginRight: "1vh" }}
                                  src={githuborange}
                                />
                                <a
                                  href="https://github.com/MassivaM/ComputerVision"
                                  style={{ color: "#E0530F" }}
                                >
                                  Introduction to Computer vision
                                </a>
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  fontWeight: "bold",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                }}
                              >
                                <a
                                  href="https://github.com/annyhang/SaboteurComp424"
                                  style={{ color: "#E0530F" }}
                                >
                                  <img
                                    style={{ marginRight: "1vh" }}
                                    src={githuborange}
                                  />
                                  Saboteur AI Autoplayer
                                </a>
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  fontWeight: "bold",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                }}
                              >
                                <a
                                  href="https://github.com/MassivaM/frosh2020"
                                  style={{ color: "#E0530F" }}
                                >
                                  <img
                                    style={{ marginRight: "1vh" }}
                                    src={githuborange}
                                  />
                                  McGill Engineering Orientation Week Official
                                  Website
                                </a>
                              </Typography>
                            </Box>
                          </Modal>
                        </div>
                        <div className="buzzle">
                          <button
                            className="buttonbuzzle"
                            onClick={handleOpen2}
                          />
                          <Modal
                            open={open2}
                            onClose={handleClose2}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style} className="modal">
                              <Typography
                                id="modal-modal-title"
                                variant="h3"
                                component="h2"
                                className="title-modal"
                                style={{
                                  fontFamily: "Montserrat",
                                  fontWeight: "800",
                                  color: "#8D3300",
                                  textAlign: "center",
                                }}
                              >
                                Buzzle
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                  margin: "2vh",
                                }}
                              >
                                Web application that allows creatives to create
                                video animatics using AI by simply uploading a
                                script and design inspiration
                              </Typography>
                              <Grid
                                container
                                spacing={3}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item xs={4}>
                                  <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                    style={{
                                      fontFamily: "Raleway",
                                      fontWeight: "bold",
                                      color: "#8D3300",
                                      textAlign: "center",
                                      fontSize: "2vh",
                                    }}
                                  >
                                    Completed <br></br> the District 3 <br></br>
                                    incubator’s <br></br>{" "}
                                    <p
                                      style={{ color: "#E0530F", margin: "0" }}
                                    >
                                      Validation <br></br>program
                                    </p>
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <img
                                    className="image-modal"
                                    style={{ align: "center" }}
                                    src={buzzle}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                    style={{
                                      fontFamily: "Raleway",
                                      fontWeight: "bold",
                                      color: "#8D3300",
                                      textAlign: "center",
                                      fontSize: "2vh",
                                    }}
                                  >
                                    Currently a <br></br>part of <br></br>
                                    District 3’s
                                    <br></br>
                                    <p
                                      style={{ color: "#E0530F", margin: "0" }}
                                    >
                                      Launch and<br></br> grow program
                                    </p>
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Typography
                                id="modal-modal-description"
                                style={{
                                  fontFamily: "Raleway",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "Raleway",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Tech stack :{" "}
                                </p>{" "}
                                Tensorflow , React , Django , BigGAN , CLIP
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                }}
                              >
                                <a
                                  href="https://buzzle.digital/"
                                  style={{ color: "#8D3300" }}
                                >
                                  <img
                                    style={{ marginRight: "1vh" }}
                                    src={web}
                                  />
                                  View website
                                </a>
                              </Typography>
                            </Box>
                          </Modal>
                        </div>
                        <div className="thankloop">
                          <button
                            className="buttonthankloop"
                            onClick={handleOpen3}
                          />
                          <Modal
                            open={open3}
                            onClose={handleClose3}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style} className="modal">
                              <Typography
                                id="modal-modal-title"
                                variant="h4"
                                component="h4"
                                style={{
                                  fontFamily: "Montserrat",
                                  fontWeight: "800",
                                  color: "#8D3300",
                                  textAlign: "center",
                                }}
                              >
                                Thankloop
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                  margin: "2vh",
                                }}
                              >
                                Web application that allows users to thank and
                                contribute to the work of people who have done
                                good in their community
                              </Typography>
                              <img
                                style={{ align: "center", marginLeft: "3vh" }}
                                src={thankloop}
                                className="image-modal-zara"
                              />
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "Raleway",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Tech stack :{" "}
                                </p>{" "}
                                React , NodeJS , GraphQL
                              </Typography>

                              <Grid
                                container
                                spacing={3}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item xs={6}>
                                  <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                    style={{
                                      fontFamily: "Raleway",
                                      color: "#8D3300",
                                      textAlign: "center",
                                      fontSize: "2vh",
                                    }}
                                  >
                                    <a
                                      href="https://github.com/MassivaM/thankloop"
                                      style={{ color: "#8D3300" }}
                                    >
                                      <img
                                        style={{ marginRight: "1vh" }}
                                        src={githuborange}
                                      />
                                      View on GitHub
                                    </a>
                                  </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                    style={{
                                      fontFamily: "Raleway",
                                      color: "#8D3300",
                                      textAlign: "center",
                                      fontSize: "2vh",
                                    }}
                                  >
                                    <a
                                      href="https://thankloop-app.herokuapp.com/#/home"
                                      style={{ color: "#8D3300" }}
                                    >
                                      <img
                                        style={{ marginRight: "1vh" }}
                                        src={web}
                                      />
                                      View project
                                    </a>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Modal>
                        </div>
                      </Tween>
                    ) : null}
                    {progress > 0.81 && progress <= 1 ? (
                      <Tween
                        from={{
                          css: {
                            opacity: "0",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                        to={{
                          css: {
                            opacity: "1",
                          },
                          ease: "Circ.easeOutExpo",
                        }}
                      >
                        <div>
                          <h1 className="thanks">
                            Thank you <br></br>for scrolling!
                          </h1>
                          <p className="scroll">let's stay in touch 💙</p>
                          <Grid
                            container
                            spacing={3}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              position: "absolute",
                              top: "70vh",
                              zIndex: "1",
                            }}
                          >
                            <Grid item xs={4}>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                }}
                              >
                                <a
                                  href="https://github.com/MassivaM"
                                  className="cursorblue"
                                  style={{
                                    color: "#134084",
                                    textDecoration: "none",
                                  }}
                                >
                                  <img
                                    className="logos"
                                    style={{
                                      marginRight: "1vh",
                                      textDecoration: "none",
                                    }}
                                    src={githubblue}
                                  />
                                  <br></br>
                                  MassivaM
                                </a>
                              </Typography>
                            </Grid>

                            <Grid item xs={4}>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                }}
                              >
                                <a
                                  href="https://www.linkedin.com/in/massy-mahamli/"
                                  className="cursorblue"
                                  style={{
                                    color: "#134084",
                                    textDecoration: "none",
                                  }}
                                >
                                  <img
                                    className="logos"
                                    style={{ marginRight: "1vh" }}
                                    src={linkedin}
                                  />
                                  <br></br>
                                  Massy Mahamli
                                </a>
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                style={{
                                  fontFamily: "Raleway",
                                  color: "#8D3300",
                                  textAlign: "center",
                                  fontSize: "2vh",
                                }}
                              >
                                <a style={{ color: "#134084" }}>
                                  <img
                                    className="logos"
                                    style={{
                                      marginRight: "1vh",
                                      textDecoration: "none",
                                    }}
                                    src={email}
                                  />
                                  <br></br>
                                  mahamlimassy@gmail.com
                                </a>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </Tween>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="titlesportrait">
                <Sequence
                  ref={ref}
                  progress={progress}
                  setLoading={setLoading}
                />
              </div>
            </div>
          )}
        </Scene>
      </Controller>
    </div>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
