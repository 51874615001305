import React, { forwardRef, useState, useEffect } from "react";
import { Skeleton } from "../Skeleton";
import ImageArray from "./ImageArray";

const ImageSequence = forwardRef(
  ({ progress, setLoading }, ref) => {
    //const newImages = ImageArray();
    const lgt = 507;
    let previousValue;
    function padLeadingZeros(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    }
    let index = Math.round(progress * 1 * (lgt - 1));
    let index2 = padLeadingZeros(index, 4);

    const value = `../images/${index2}.png`;
    const test = `A${index}`;
    console.log("test" + test);

    let img2 =
      "https://res.cloudinary.com/thankloop/image/upload/q_auto,f_auto,fl_lossy,w_auto,dpr_auto,c_limit/v1638663959/portfolio/0000.png";

    var img =
      "https://res.cloudinary.com/thankloop/image/upload/q_auto,f_auto,fl_lossy,w_auto,dpr_auto,c_limit/v1638663959/portfolio/" +
      index2 +
      ".png";
    img2 = img;
    //if (index !== undefined) {
    // if (img == undefined) {
    //   //setLoading(true);
    //   return <Skeleton width="100%" height="100%" />;
    // } else {
    //handleLoading();
    //console.log(index2);
    //setLoading(false);
    return (
      <div>
        <img
          ref={ref}
          className="image-background"
          // src={require(`../images/${index2}.png`)}
          src={img2}
        ></img>
      </div>
    );
    //}
  }
  //previousValue = value;
);

export default ImageSequence;
